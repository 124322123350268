import React, { useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import links from "../constants/links"

const Footer = () => {
  return (
    <footer className="full-width-container">
      <div className="footer fixed-width-container">
        <div className="logo">
          <AniLink to="/" cover bg="#000" direction="down">
            <h1>yt</h1>
            {/* <img src={Icon} /> */}
          </AniLink>
        </div>
        <div className="footer__navigation">
          {links.map((item, index) => {
            return (
              <AniLink
                key={index}
                to={item.path}
                cover
                direction="down"
                bg="#000"
                top="exit"
              >
                {item.text}
              </AniLink>
            )
          })}
        </div>
        <div className="footer__copyright">
          copyright &copy; {new Date().getFullYear()} all rights reserved
        </div>
      </div>
    </footer>
  )
}

export default Footer
