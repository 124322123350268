import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  forwardRef,
} from "react"
import { Link } from "gatsby"
import gsap from "gsap"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Hamburger from "./Hamburger"
import Icon from "../images/icon.png"

const Header = forwardRef((props, ref) => {
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: "Menu",
  })

  const { headerLogo, headerMenu, headerContainer } = ref

  const [disabled, setDisabled] = useState(false)

  const handleMenu = () => {
    disableMenu()
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: "Close",
      })
      bodyFixed()
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: "Menu",
      })
      disableBodyFixed()
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: "Close",
      })
      bodyFixed()
    }
  }

  // Determine if menu button should be disabled
  const disableMenu = () => {
    setDisabled(!disabled)
    setTimeout(() => {
      setDisabled(false)
    }, 1200)
  }

  const bodyFixed = e => {
    document.querySelector("body").style.position = "fixed"
  }

  const disableBodyFixed = e => {
    document.querySelector("body").style.position = "initial"
  }

  useEffect(() => {})

  return (
    <section
      ref={headerContainer}
      className="fixed-width-container header-container"
    >
      <header>
        <div className="container">
          <div className="wrapper">
            <div className="inner-header">
              <div ref={headerLogo} className="logo">
                <AniLink
                  cover
                  bg="#000"
                  to="/"
                  direction="right"
                  onClick={e => disableBodyFixed(e)}
                >
                  <h1>yt</h1>
                </AniLink>
              </div>
              <div ref={headerMenu} className="menu">
                <button disabled={disabled} onClick={handleMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <Hamburger state={state} />
      </header>
    </section>
  )
})

export default Header
