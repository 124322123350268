/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, forwardRef } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./Footer"

import "../sass/layout.scss"

const Layout = forwardRef(({ children }, ref) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  //Smooth scroll ref: https://www.wcj.io/blog/gatsby-smooth-scrolling
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <>
      {/* <LoaderContextProvider> */}
      <Header siteTitle={data.site.siteMetadata.title} ref={ref} />

      {children}
      <Footer />
      {/* </LoaderContextProvider> */}
    </>
  )
})

export default Layout
