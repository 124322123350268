import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import gsap from "gsap"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Hamburger = ({ state }) => {
  // Vars for our animated dom nodes
  let menu = useRef(null)
  let revealMenu = useRef(null)
  let revealMenuBackground = useRef(null)
  let line1 = useRef(null)
  let line2 = useRef(null)
  let line3 = useRef(null)
  let info = useRef(null)

  useEffect(() => {
    if (state.clicked === false) {
      // Close menu
      gsap.to([revealMenu, revealMenuBackground], {
        duration: 0.8,
        height: 0,
        ease: "power3.inOut",
        stagger: {
          amount: 0.07,
        },
      })
      gsap.to(menu, {
        duration: 1,
        css: { display: "none" },
      })
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      // Open Menu
      gsap.to(menu, {
        duration: 0,
        css: { display: "block" },
      })
      gsap.to([revealMenuBackground, revealMenu], {
        duration: 0,
        opacity: 1,
        height: "100%",
      })
      // Add state as a dependency at end so that it waits for state change
      staggerReveal(revealMenuBackground, revealMenu)
      fadeInUp(info)
      staggerText(line1, line2, line3)
    }
  }, [state])

  const staggerReveal = (node1, node2) => {
    gsap.from([node1, node2], {
      duration: 0.8,
      height: 0,
      transformOrigin: "right top",
      skewY: 2,
      ease: "power3.inOut",
      stagger: {
        amount: 0.1,
      },
    })
  }

  const staggerText = (node1, node2, node3) => {
    gsap.from(
      [node1.children.item(0), node2.children.item(0), node3.children.item(0)],
      {
        duration: 0.8,
        y: 100,
        delay: 0.1,
        ease: "power3.inOut",
        stagger: {
          amount: 0.3,
        },
      }
    )
  }

  const fadeInUp = node => {
    gsap.from(node, {
      y: 60,
      duration: 1,
      delay: 0.2,
      opacity: 0,
      ease: "power3.inOut",
    })
  }

  const handleHover = e => {
    gsap.to(e.target, {
      duration: 0.3,
      y: 3,
      skewX: 4,
      ease: "power3.inOut",
    })
  }

  const handleHoverExit = e => {
    gsap.to(e.target, {
      duration: 0.3,
      y: -3,
      skewX: 0,
      ease: "power3.inOut",
    })
  }

  const disableBodyFixed = e => {
    document.querySelector("body").style.position = "initial"
  }

  return (
    <div ref={el => (menu = el)} className="hamburger-menu">
      <div
        ref={el => (revealMenuBackground = el)}
        className="menu-secondary-background-color"
      ></div>
      <div ref={el => (revealMenu = el)} className="menu-layer">
        <div className="container">
          <div className="wrapper">
            <div className="menu-links fixed-width-container">
              <nav>
                <ul>
                  <li ref={el => (line1 = el)}>
                    <AniLink
                      swipe
                      onMouseEnter={e => handleHover(e)}
                      onMouseOut={e => handleHoverExit(e)}
                      to="/about"
                      onClick={e => disableBodyFixed(e)}
                    >
                      About
                    </AniLink>
                  </li>
                  <li ref={el => (line2 = el)}>
                    <AniLink
                      swipe
                      onMouseEnter={e => handleHover(e)}
                      onMouseOut={e => handleHoverExit(e)}
                      to="/portfolio"
                      onClick={e => disableBodyFixed(e)}
                    >
                      Portfolio
                    </AniLink>
                  </li>
                  <li ref={el => (line3 = el)}>
                    <AniLink
                      swipe
                      onMouseEnter={e => handleHover(e)}
                      onMouseOut={e => handleHoverExit(e)}
                      to="/contact"
                      onClick={e => disableBodyFixed(e)}
                    >
                      Contact
                    </AniLink>
                  </li>
                </ul>
              </nav>
              <div ref={el => (info = el)} className="info">
                <h3>Let's create!</h3>
                <p>
                  Check out my latest work on my portfolio page or if you'd like
                  to get in touch, send me a message and let's connect!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hamburger
